import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowDetectionService {
  isMainWindow(): boolean {
    return window.name !== 'impersonateWindow';
  }

  isEmbeddedWindow(): boolean {
    return window.name === 'impersonateWindow';
  }
}
