import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IModalComponent } from '../modal-component.interface';

@Component({
  selector: 'lib-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmComponent implements AfterViewInit, IModalComponent {
  @Input() message: string = null;

  @Output() readonly close$ = new EventEmitter();
  @Output() readonly submit$ = new EventEmitter();

  @ViewChild('closeButton', { static: true, read: ElementRef }) private closeButton: ElementRef<HTMLButtonElement>;

  ngAfterViewInit() {
    this.closeButton.nativeElement.focus();
  }

  confirm() {
    this.submit$.emit(true);
  }

  cancel() {
    this.submit$.emit(false);
    this.close$.emit();
  }
}
