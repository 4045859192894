import { Injectable } from '@angular/core';
import { environment } from '@env';
import { LocalStorageService } from './local-storage.service';
import { HcConfig } from '../view-models/hc-config';

@Injectable({
  providedIn: 'root',
})
export class HcService {
  constructor(private localStorageService: LocalStorageService) {}

  setHealthCenterId(healthCenterId: string): void {
    if (healthCenterId) {
      this.localStorageService.store('healthCenterId', healthCenterId);
    } else {
      this.localStorageService.clear('healthCenterId');
    }
  }

  getHealthCenterId(): string | null {
    const healthCenterId = this.localStorageService.getItem('healthCenterId');

    if (!healthCenterId) {
      if (
        window.location.host.includes('app.piauisaudedigital.com.br') ||
        window.location.search.includes('debug=piaui')
      ) {
        return environment.environmentVariables.healthCenterIdPiaui;
      } else if (
        window.location.host.includes('app.conexaosaudepovos.com.br') ||
        window.location.search.includes('debug=povosdafloresta')
      ) {
        return environment.environmentVariables.healthCenterIdPovosDaFloresta;
      }
    }

    return this.localStorageService.getItem('healthCenterId');
  }

  isPiaui(): boolean {
    const healthCenterId = this.getHealthCenterId();
    if (!healthCenterId) {
      return window.location.host.includes('app.piauisaudedigital.com.br');
    }

    return healthCenterId === environment.environmentVariables.healthCenterIdPiaui;
  }

  setPiauiHealthCenter(): void {
    this.setHealthCenterId(environment.environmentVariables.healthCenterIdPiaui);
  }

  isPovosDaFloresta(): boolean {
    const healthCenterId = this.getHealthCenterId();
    if (!healthCenterId) {
      return window.location.host.includes('app.conexaosaudepovos.com.br');
    }
    return healthCenterId === environment.environmentVariables.healthCenterIdPovosDaFloresta;
  }

  setPovosDaFlorestaHealthCenter(): void {
    this.setHealthCenterId(environment.environmentVariables.healthCenterIdPovosDaFloresta);
  }

  setNotDefinedYetHealthCenter(): void {
    this.setHealthCenterId('');
  }

  getHcConfig(): HcConfig {
    if (this.isPiaui()) {
      return {
        appName: 'Piauí Saúde Digital',
        logo: './assets/logos/piaui-logo2.png',
        hcId: environment.environmentVariables.healthCenterIdPiaui,
        links: {
          site: 'https://app.piauisaudedigital.com.br',
          termsOfUse: 'https://piauisaudedigital.com.br/termos-de-uso.html',
          policyPrivacy: 'https://piauisaudedigital.com.br/politica-de-privacidade.html',
          playstore: 'https://play.google.com/store/apps/details?id=com.ptm.piaui',
          appstore: 'https://apps.apple.com/br/app/piau%C3%AD-sa%C3%BAde-digital/id6470301042',
        },
      };
    } else if (this.isPovosDaFloresta()) {
      return {
        appName: 'Conexão Saúde Digital',
        logo: '../../../assets/logos/logo-povos-da-floresta-green.png',
        hcId: environment.environmentVariables.healthCenterIdPovosDaFloresta,
        links: {
          site: 'https://app.conexaosaudepovos.com.br',
          termsOfUse: 'https://conexaosaudepovos.com.br/termos-de-uso-conexao-saude',
          policyPrivacy: 'https://conexaosaudepovos.com.br/politica-de-privacidade-conexao-saude',
          playstore: 'https://play.google.com/store/apps/details?id=com.ptm.conexaopovosfloresta',
          appstore: 'https://apps.apple.com/us/app/conexão-saúde/id6736998071',
        },
      };
    } else {
      return {
        appName: 'SOS Portal',
        logo: '../../../assets/logos/logo-small.svg',
        hcId: '',
        links: {
          site: 'https://app.sosportal.com.br',
          termsOfUse: 'https://portaltelemedicina.com.br/termos-e-condicoes-gerais-de-uso',
          policyPrivacy: 'https://portaltelemedicina.com.br/politica-de-privacidade',
          playstore: 'https://play.google.com/store/apps/details?id=com.ptm.sosportal',
          appstore: 'https://apps.apple.com/br/app/isalut/id1506057413',
        },
      };
    }
  }
}
