import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { EDoctorStatusDTO, EDoctorStatusUpdateReasonDTO } from '@project/view-models';
import { DoctorProfileApiProviderService, SocketMessagesDataProviderService } from '@project/data-providers';
import { IFeatureService } from './interfaces/feature-serivice.interface';
import { UserProfileDataService } from './user-profile-data.service';
import { HcService } from './hc-service';

@Injectable({
  providedIn: 'root',
})
export class DoctorSessionStatusFeatureService implements IFeatureService {
  public status$: Observable<EDoctorStatusDTO> = this.userProfileDataService.profile$.pipe(
    map((profile) => profile?.doctorStatus),
  );

  private destroyed$ = new Subject();

  private _doctorIsForcedToOffline$ = new Subject();
  public doctorIsForcedToOffline$ = this._doctorIsForcedToOffline$.asObservable();

  constructor(
    private socketMessagesProviderService: SocketMessagesDataProviderService,
    private userProfileDataService: UserProfileDataService,
    private doctorProfileApiProviderService: DoctorProfileApiProviderService,
    private hcService: HcService,
  ) {}

  public initialise(): Observable<void> {
    this.trackDoctorStatusUpdate();
    return of(null);
  }

  public destroy(): Observable<void> {
    this.destroyed$.next();
    return of(null);
  }

  public setDoctorStatus(status: EDoctorStatusDTO): Observable<void> {
    return this.doctorProfileApiProviderService
      .setDoctorStatus(status, this.hcService.getHealthCenterId())
      .pipe(tap(() => this.setDoctorStatusToModel(status)));
  }

  private trackDoctorStatusUpdate() {
    this.socketMessagesProviderService.doctorStatusUpdated$
      .pipe(
        filter((event) => event.doctorId === this.userProfileDataService.profile?.id),
        takeUntil(this.destroyed$),
      )
      .subscribe((event) => {
        this.setDoctorStatusToModel(event.status);

        if (event.status === EDoctorStatusDTO.Away && event.reason === EDoctorStatusUpdateReasonDTO.System) {
          this._doctorIsForcedToOffline$.next();
        }
      });
  }

  private setDoctorStatusToModel(status: EDoctorStatusDTO) {
    this.userProfileDataService.updateProfileDate({ doctorStatus: status });
  }
}
