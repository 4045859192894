import { Injectable } from '@angular/core';
import { HcService } from '@project/services';
import { environment } from '@env';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(private hcService: HcService) {}

  initialize() {
    const isHomePagePiaui =
      window.location.host.includes('app.piauisaudedigital.com.br') || window.location.search.includes('debug=piaui');

    if (isHomePagePiaui) {
      this.hcService.setPiauiHealthCenter();
    }

    const isHomePagePovosDaFloresta =
      window.location.host.includes('app.conexaosaudepovos.com.br') ||
      window.location.search.includes('debug=povosdafloresta');

    if (isHomePagePovosDaFloresta) {
      this.hcService.setPovosDaFlorestaHealthCenter();
    }

    if (this.hcService.isPiaui()) {
      document.body.classList.add('piaui');
      const logo = document.querySelector('.splash__logo') as HTMLImageElement;
      if (logo) {
        logo.src = 'assets/logos/piaui-logo2.png';
      }
      // eslint-disable-next-line @typescript-eslint/quotes
      const favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement;
      if (favicon) {
        favicon.href = '/assets/piaui-favicon.jpg';
      }

      // Google Analytics for Piaui
      window.dataLayer = window.dataLayer || [];
      window.gtag =
        window.gtag ||
        // eslint-disable-next-line space-before-function-paren
        function () {
          window.dataLayer.push(arguments);
        };
      window.gtag('js', new Date());
      window.gtag('config', environment.environmentVariables.GATrackers.GA_TRACKING_ID_POVOS_DA_FLORESTA);
    } else if (this.hcService.isPovosDaFloresta()) {
      document.body.classList.add('povos-da-floresta');
      const logo = document.querySelector('.splash__logo') as HTMLImageElement;
      if (logo) {
        logo.src = 'assets/logos/logo-povos-da-floresta-green.png';
      }
      // eslint-disable-next-line @typescript-eslint/quotes
      const favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement;
      if (favicon) {
        favicon.href = '/assets/logo-povos-da-floresta.png';
      }

      // Google Analytics for Povos da Floresta
      window.dataLayer = window.dataLayer || [];
      window.gtag =
        window.gtag ||
        // eslint-disable-next-line space-before-function-paren
        function () {
          window.dataLayer.push(arguments);
        };
      window.gtag('js', new Date());
      window.gtag('config', environment.environmentVariables.GATrackers.GA_TRACKING_ID_POVOS_DA_FLORESTA);
    } else {
      // Google Analytics for SOS
      window.dataLayer = window.dataLayer || [];
      window.gtag =
        window.gtag ||
        // eslint-disable-next-line space-before-function-paren
        function () {
          window.dataLayer.push(arguments);
        };
      window.gtag('js', new Date());
      window.gtag('config', environment.environmentVariables.GATrackers.GA_TRACKING_ID_SOS);
    }
  }
}
