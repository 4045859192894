<main>
  <div *ngIf="mediaDeviceToAsk === mediaDevices.Audio">
    <div class="icon">
      <lib-icon type="mic-white"></lib-icon>
    </div>

    <h1 class="h1 text-align-center">
      <span>
        {{ "media-devices-overlay.require.microphone.title" | translate }}
      </span>
    </h1>
  </div>

  <div *ngIf="mediaDeviceToAsk === mediaDevices.Video">
    <div class="icon">
      <lib-icon type="videocam-white"></lib-icon>
    </div>

    <h1 class="h1 text-align-center">
      <span>
        {{ "media-devices-overlay.require.camera.title" | translate }}
      </span>
    </h1>
  </div>

  <div class="device" *ngIf="mediaDeviceToAsk === mediaDevices.Both">
    <div class="icon">
      <lib-icon type="videocam-white"></lib-icon>
      <lib-icon type="mic-white"></lib-icon>
    </div>

    <h1 class="h1 text-align-center">
      <span>
        {{ "media-devices-overlay.require.both.title" | translate }}
      </span>
    </h1>
  </div>

  <div class="card">
    <h2 class="h2">
      {{ "media-devices-overlay.require.card.title" | translate }}
    </h2>

    <ul>
      <li>{{ "media-devices-overlay.require.card.step-1" | translate }}</li>
      <li>{{ "media-devices-overlay.require.card.step-2" | translate }}</li>
      <li>
        {{
          "media-devices-overlay.require.card.step-3"
            | translate
              : {
                  appName: isPovosDaFloresta
                    ? "Povos da Floresta Saúde Digital"
                    : isPiaui
                    ? "Piauí Saúde Digital"
                    : "SOS Portal"
                }
        }}
      </li>
    </ul>
  </div>

  <button libButton appearance="light-muted-outline" size="l" (click)="close$.emit()">
    {{ "actions.cancel" | translate }}
  </button>
</main>
