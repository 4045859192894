import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { VideoconferenceImpl } from './videoconference.impl';
import { IModalComponent, IModalComponentRef, ModalOverlayService, ModalWindowRef } from '@lib/modal';
import { EProfileTypeDTO, IRequisition, IRequisitionParticipant } from '@project/view-models';
import { VideoCallManagerService } from 'src/app/features/modules/video-call/video-call-manager.service';
import { TranslateService } from '@project/translate';
import { BehaviorSubject } from 'rxjs';
import { ModalQrCodeComponent } from '../../project/components/modal-qr-code/modal-qr-code.component';
import { QrCodeService } from '../../project/components/modal-qr-code/qr-code.service';
import { NotificationsService } from '@lib/notifications';
import { HcService } from '@project/services';
import { environment } from '@env';

@Component({
  selector: 'app-videoconference',
  templateUrl: './videoconference.component.html',
  styleUrls: ['./videoconference.component.scss'],
})
export class VideoconferenceComponent implements OnInit, IModalComponent, OnDestroy {
  private qrCodeBehaviorSubject$ = new BehaviorSubject<string>(null);
  public qrCodeObservable = this.qrCodeBehaviorSubject$.asObservable();
  private timerBehaviorSubject$ = new BehaviorSubject<{ minutes: number; seconds: number }>({
    minutes: 5,
    seconds: 0,
  });
  public timerObservable = this.timerBehaviorSubject$.asObservable();
  private isQrCodeExpiredBehaviorSubject$ = new BehaviorSubject<boolean>(true);
  public isQrCodeExpiredObservable = this.isQrCodeExpiredBehaviorSubject$.asObservable();
  private hasQrCodeBeenGenerated = false;
  private isQrCodeActive = false;
  private countdownInterval: ReturnType<typeof setInterval>;
  public readonly mainProfileTypeDTO: IRequisitionParticipant = this.videoCallManagerService.participant;
  public readonly secundaryProfileTypeDTO: IRequisitionParticipant = this.videoCallManagerService
    .myProfile as IRequisitionParticipant;
  public readonly requisition: IRequisition = this.videoCallManagerService.requisition;

  public showChat = true;
  public isPatient = false;
  public isPiaui: boolean;
  public isPovosDaFloresta: boolean;

  public readonly close$ = new EventEmitter();
  public readonly submit$ = new EventEmitter();

  private disableChatBehavior$ = new BehaviorSubject<boolean>(false);
  public disableChatObservable$ = this.disableChatBehavior$.asObservable();

  private disableRenderChat =
    this.secundaryProfileTypeDTO.profileType === EProfileTypeDTO.Doctor ||
    this.secundaryProfileTypeDTO.profileType === EProfileTypeDTO.Receptionist;

  private ModalQrCodeRef: IModalComponentRef<ModalQrCodeComponent>;

  constructor(
    public videoconference: VideoconferenceImpl,
    private modalOverlayService: ModalOverlayService,
    private videoCallManagerService: VideoCallManagerService,
    public modalWindowRef: ModalWindowRef,
    private qrCodeService: QrCodeService,
    private notificationService: NotificationsService,
    private hcService: HcService,
  ) {}

  ngOnInit(): void {
    this.isPiaui = this.hcService.isPiaui();
    this.isPovosDaFloresta = this.hcService.isPovosDaFloresta();
    this.join();
    this.observerEvents();
    this.setupModal();
    this.renderChat();
    this.checkIfPatientType();
  }

  startCountdown() {
    if (this.countdownInterval) {
      return;
    }

    this.countdownInterval = setInterval(() => {
      let { minutes, seconds } = this.timerBehaviorSubject$.value;

      if (seconds === 0) {
        if (minutes === 0) {
          this.isQrCodeExpiredBehaviorSubject$.next(true);
          this.clearCountdown();
          this.hasQrCodeBeenGenerated = false;
        } else {
          minutes--;
          seconds = 59;
        }
      } else {
        seconds--;
      }

      this.timerBehaviorSubject$.next({ minutes, seconds });
    }, 1000);
  }

  clearCountdown() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
    }
  }

  public checkIfPatientType(): void {
    this.isPatient = this.secundaryProfileTypeDTO.profileType === EProfileTypeDTO.Patient;
  }

  private getHealthCenter(): string {
    if (this.isPiaui) {
      return 'piaui';
    } else if (this.isPovosDaFloresta) {
      return 'povosdafloresta';
    }
    return 'sos';
  }

  private getDomainUrl(): string {
    if (this.isPiaui) {
      return environment.environmentVariables.appPiauiDomainUrl;
    } else if (this.isPovosDaFloresta) {
      return environment.environmentVariables.appPovosDaFlorestaDomainUrl;
    }
    return environment.environmentVariables.appDomainUrl;
  }

  public async generateQrCode() {
    this.isQrCodeExpiredBehaviorSubject$.next(false);
    this.clearCountdown();

    this.timerBehaviorSubject$.next({ minutes: 5, seconds: 0 });
    this.startCountdown();

    try {
      const response = await this.qrCodeService.getAuthCode().toPromise();
      const domainUrl = this.getDomainUrl();
      const healthCenterParam = this.getHealthCenter();
      const qrCodeUrl = `${domainUrl}/upload-external-files/${this.requisition.chatId}?code=${response.external_auth_code}&hc=${healthCenterParam}`;
      this.qrCodeBehaviorSubject$.next(qrCodeUrl);
    } catch (error) {
      this.notificationService.error({
        message: TranslateService.localize('error.search.external.code'),
      });
    }
  }

  public openModalQrCode() {
    this.ModalQrCodeRef = this.modalOverlayService.openOverlay<ModalQrCodeComponent>(ModalQrCodeComponent);

    const { minutes, seconds } = this.timerBehaviorSubject$.value;

    if ((!this.hasQrCodeBeenGenerated || (minutes === 0 && seconds === 0)) && !this.isQrCodeActive) {
      this.generateQrCode();
      this.hasQrCodeBeenGenerated = true;
    }

    this.ModalQrCodeRef.instance.qrCode$ = this.qrCodeObservable;
    this.ModalQrCodeRef.instance.timer$ = this.timerObservable;
    this.ModalQrCodeRef.instance.isQrCodeExpired$ = this.isQrCodeExpiredObservable;

    this.ModalQrCodeRef.instance.regenerate$.subscribe(() => {
      this.isQrCodeActive = true;
      this.generateQrCode();
    });

    this.ModalQrCodeRef.instance.close$.subscribe(() => {
      this.ModalQrCodeRef.close();
    });
  }

  public toggleChat(): void {
    this.showChat = !this.showChat;
  }

  public async join(): Promise<void> {
    await this.videoconference.join();
  }

  public async toggleVideo(): Promise<void> {
    await this.videoconference.toggleVideo();
  }

  public async toggleAudio(): Promise<void> {
    await this.videoconference.toggleSound();
  }

  public async left(): Promise<void> {
    this.modalOverlayService.confirm(TranslateService.localize('questions.are-you-sure?')).subscribe(async () => {
      await this.videoconference.left();
      await this.videoCallManagerService.endCurrentCall();
    });
  }

  public async exit() {
    await this.videoconference.left();
  }

  private observerEvents() {
    this.modalWindowRef.isFullscreenMode$.subscribe((isFullScreen: boolean) => {
      if (!this.disableRenderChat) {
        this.showChat = isFullScreen;
        this.disableChatBehavior$.next(isFullScreen);
      }
    });
  }

  private setupModal() {
    this.modalWindowRef.updateConfig({
      disableChangeWindowMode: this.mainProfileTypeDTO.profileType !== EProfileTypeDTO.Patient,
    });
  }

  private renderChat() {
    if (this.disableRenderChat) {
      this.disableChatBehavior$.next(false);
      this.showChat = false;
    }
  }

  ngOnDestroy(): void {
    this.clearCountdown();
  }
}
