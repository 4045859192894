import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, mapTo, share, skip } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BroadcastEventsService, MetaService } from '@core/services';
import { CloseAsidePageEvent, EAsideOutlets, IRouteData } from '@project/shared';
import { AppInitializerService } from './app.component.service';
import { HcService } from '@project/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private routeChanges$: Observable<ActivatedRoute>;

  public readonly asideOutlets = EAsideOutlets;

  public isPiaui: boolean;

  public isPovosDaFloresta: boolean;

  constructor(
    private appInitializerService: AppInitializerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaService,
    private broadcastEventsService: BroadcastEventsService,

    private hcService: HcService,
  ) {}

  public ngOnInit(): void {
    this.appInitializerService.initialize();
    this.isPiaui = this.hcService.isPiaui();
    this.isPovosDaFloresta = this.hcService.isPovosDaFloresta();
    this.initializePreloader();
    this.routeChanges$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      mapTo(this.activatedRoute),
      map((route) => {
        while (route?.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => !!route?.snapshot?.data),
      share(),
    );

    this.initTitleRefresh();
    this.closeAsideOutletsOnPrimaryRouteChange();
  }
  private initializePreloader(): void {
    const preloader = document.getElementById('preloader');
    const preloaderImage = document.getElementById('preloader-image');

    if (preloader && preloaderImage) {
      if (this.isPiaui) {
        preloaderImage.style.backgroundImage = 'url("assets/logos/piaui-logo.png")';
      } else if (this.isPovosDaFloresta) {
        preloaderImage.style.backgroundImage = 'url("assets/logos/povos-da-floresta-logo.png")';
      } else {
        preloaderImage.style.backgroundImage = 'url("assets/logos/logo.svg")';
      }

      setTimeout(() => {
        preloader.style.display = 'none';
      }, 1000);
    }
  }

  private initTitleRefresh() {
    this.routeChanges$
      .pipe(
        filter((route) => route.outlet === 'primary'),
        map((route) => {
          /**
           * if route hasn't 'titleCreator' in it's data, get nearest 'titleCreator' from parents routes
           */
          while (route && !route?.snapshot?.data?.titleCreator) {
            route = route.parent;
          }

          return route?.snapshot.data || {};
        }),
        distinctUntilChanged(),
      )
      .subscribe(({ titleCreator }: IRouteData) => {
        let title = 'SOS Portal';
        if (this.hcService.isPiaui()) {
          title = 'Piauí Saúde Digital';
        } else if (this.hcService.isPovosDaFloresta()) {
          title = 'Povos da Floresta Saúde Digital';
        }
        this.metaService.setTitle(titleCreator ? titleCreator() : title);
      });
  }

  private closeAsideOutletsOnPrimaryRouteChange() {
    this.routeChanges$
      .pipe(
        filter((route) => route.outlet === 'primary'),
        distinctUntilChanged(),
        skip(1), // skip initial route navigation when user can open outlet page from bookmarks
      )
      .subscribe(() => {
        this.broadcastEventsService.broadcast(new CloseAsidePageEvent());
      });
  }
}
