import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@env';
import * as amplitude from '@amplitude/analytics-browser';
import { monitorLogger } from './app/project/monitor/monitor-logger';
import { version } from '../package.json';
import {
  handlerLocalStorageChange,
  handlerDOMContentLoaded,
  handlerPermissionListener,
} from './app/project/monitor/handlers';

amplitude.init(environment.environmentVariables.amplitudeApiKey, {
  autocapture: false,
  appVersion: version,
});

if (environment.production) {
  enableProdMode();
  monitorLogger.initializeAnalytics('production');
} else {
  monitorLogger.initializeAnalytics('debug');
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

handlerDOMContentLoaded();
handlerPermissionListener();
handlerLocalStorageChange();
