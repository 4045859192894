<lib-modal-header (close)="close$.emit()">
  {{ "modals.confirm.title" | translate }}
</lib-modal-header>
<lib-modal-body>
  <main>
    {{ message }}
  </main>
</lib-modal-body>
<lib-modal-footer class="footer">
  <button #closeButton (click)="submit$.emit(false)" libButton [appearance]="'outline'">
    {{ "modals.confirm.actions.no" | translate }}
  </button>
  <button (click)="submit$.emit(true)" libButton [appearance]="'primary'">
    {{ "modals.confirm.actions.yes" | translate }}
  </button>
</lib-modal-footer>
