import { monitorLogger } from '../monitor-logger';

export function handlerLocalStorageChange() {
  window.addEventListener('storage', (event: StorageEvent) => {
    if (event.key && event.key.startsWith('AMP_')) {
      return;
    }
    if (event.storageArea === localStorage || event.storageArea === sessionStorage) {
      monitorLogger.app.track('[STORAGE]', {
        storageName: getStorageName(event.storageArea),
        key: event.key,
        oldValue: event.oldValue,
        newValue: event.newValue,
      });
    }
  });
}

function getStorageName(storage?: Storage) {
  if (storage === localStorage) {
    return 'localStorage';
  } else if (storage === sessionStorage) {
    return 'sessionStorage';
  } else {
    return 'unknown';
  }
}
