import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { ApiService } from '@core/http';
import { EDoctorStatusDTO } from '@project/view-models';

@Injectable({
  providedIn: 'root',
})
export class DoctorProfileApiProviderService {
  constructor(private apiService: ApiService) {}

  setDoctorStatus(status: EDoctorStatusDTO, hcId: string): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Doctors/me/${hcId}/status`, { status });
  }
}
