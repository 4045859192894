import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiHelper } from './api.helper';
import { HcService } from '@project/services';
import { monitorLogger } from '../../project/monitor/monitor-logger';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  protected constructor(protected http: HttpClient, private hcService: HcService) {}

  public get<T = any>(
    url: string,
    queryParams: Record<string, any> = {},
    responseType: 'json' | 'text' = 'json',
  ): Observable<T> {
    return this.request('GET', url, null, queryParams, responseType).pipe(map((response) => response as T));
  }

  public post<T = any>(
    url: string,
    postData: Record<string, any> | string = {},
    responseType: 'json' | 'text' = 'json',
  ): Observable<T> {
    const payload = typeof postData === 'string' ? JSON.stringify(postData) : postData;

    return this.request('POST', url, payload, {}, responseType).pipe(map((response) => response as T));
  }

  public put<T = any>(
    url: string,
    postData: Record<string, any> | string = {},
    responseType: 'json' | 'text' = 'json',
  ): Observable<T> {
    const payload = typeof postData === 'string' ? JSON.stringify(postData) : postData;

    return this.request('PUT', url, payload, {}, responseType).pipe(map((response) => response as T));
  }

  public postWithForm<T = any>(url: string, postData: FormData): Observable<T> {
    return this.http.post<T>(url, postData).pipe(map((response) => response as T));
  }

  public delete<T = void>(url: string, body?: any, responseType: 'json' | 'text' = 'json'): Observable<T> {
    return this.request('DELETE', url, body, {}, responseType).pipe(map((response) => response as T));
  }

  protected request(
    method: 'GET' | 'POST' | 'DELETE' | 'PUT' = 'GET',
    url: string,
    body: any = null,
    params: Record<string, any> = {},
    responseTypeInput: 'json' | 'text' = 'json',
  ): Observable<unknown> {
    const isPiaui = this.hcService.isPiaui();
    const isPovosDaFloresta = this.hcService.isPovosDaFloresta();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      ...(isPiaui ? { 'PTM-Client-Domain': 'piaui' } : {}),
      ...(isPovosDaFloresta ? { 'PTM-Client-Domain': 'CONEXAOPOVOSFLORESTA' } : {}),
    });

    const reqParams = params ? ApiHelper.convertObjectToHttpParams(params) : null;

    const request = new HttpRequest(method, url, body, {
      headers,
      params: reqParams,
      responseType: responseTypeInput,
    });

    monitorLogger.http.request(method, url, body);

    return this.http.request(request).pipe(
      filter((r) => {
        return r instanceof HttpResponse;
      }),
      map((response: HttpResponse<any>) => {
        monitorLogger.http.response(method, response.status, response.url, response.body);
        return response.body;
      }),
    );
  }
}
