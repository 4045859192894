import { IPatientExam, IPatientExamDTO } from '@project/view-models';

export class PatientExamViewModelFactory {
  static createGetViewModel(dto: IPatientExamDTO): IPatientExam {
    return {
      exame_id: dto.exame_id,
      especialidade: dto.especialidade,
      data_abertura: dto.data_abertura,
      exame_tipo: dto.exame_tipo,
    };
  }
}
